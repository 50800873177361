import axios from '@axios';

const fetchCourseList = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/courses', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourse = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/courses/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseWaitlist = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/courses/${id}/waitlist`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const deleteCourse = (ctx, { id }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/roster/courses/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createCourse = (ctx, { courseData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/roster/courses', courseData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateCourse = (ctx, { courseId, courseData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  if (courseData && courseData.id) {
    delete courseData.id;
  }
  if (courseData && courseData.createdAt) {
    delete courseData.createdAt;
  }
  if (courseData && courseData.updatedAt) {
    delete courseData.updatedAt;
  }

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/courses/${courseId}`, courseData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  fetchCourseList,
  fetchCourse,
  fetchCourseWaitlist,
  deleteCourse,
  createCourse,
  updateCourse,
};
