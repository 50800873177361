import axios from '@axios';

const createPathwayCohort = (ctx, { cohortId, pathwayCohortData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/pathways/${cohortId}/cohorts`, pathwayCohortData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchPathwayCohortList = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/pathways/-/cohorts', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchPathwayCohortListLive = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/pathways/-/cohorts/live', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchPathwayCohortListUpcoming = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/pathways/-/cohorts/upcoming', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchPathwayCohort = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/pathways/-/cohorts/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const fetchPathwayCohortRoster = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/sessions/${id}/export`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchPathwayCohortSectionRoster = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/pathways/-/cohorts/${id}/export-sections`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const fetchPathwayCohortSummary = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/pathways/-/cohorts/${id}/summary`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const fetchPathwayCohortStudents = (ctx, { cohortId, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/pathways/-/cohorts/${cohortId}/students`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updatePathwayCohort = (ctx, { cohortId, pathwayCohortData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  delete pathwayCohortData.id;
  delete pathwayCohortData.createdAt;
  delete pathwayCohortData.updatedAt;

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/pathways/-/cohorts/${cohortId}`, pathwayCohortData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const addStudentsToPathwayCohort = (ctx, { cohortId, profileIds, studentIds, metadata }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/pathways/-/cohorts/${cohortId}/students`, { profileIds, studentIds, metadata })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const removeStudentsFromPathwayCohort = (ctx, { cohortId, profileIds }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/roster/pathways/-/cohorts/${cohortId}/students`, { data: { profileIds } })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createCourseSectionsToPathwayCohort = (ctx, { cohortId, sectionIds }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/roster/sessions/${cohortId}/courses`, { sectionIds })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const removeSectionsFromPathwayCohort = (ctx, { cohortId, sectionIds }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v1/roster/sessions/${cohortId}/courses`, { data: { sectionIds } })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  createPathwayCohort,
  fetchPathwayCohortList,
  fetchPathwayCohortListLive,
  fetchPathwayCohortListUpcoming,
  fetchPathwayCohort,
  fetchPathwayCohortRoster,
  fetchPathwayCohortSectionRoster,
  fetchPathwayCohortSummary,
  fetchPathwayCohortStudents,
  updatePathwayCohort,
  addStudentsToPathwayCohort,
  removeStudentsFromPathwayCohort,
  createCourseSectionsToPathwayCohort,
  removeSectionsFromPathwayCohort,
};
