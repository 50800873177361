import axios from '@axios';
//
// addSession(ctx, { sessionData }) {
//     return new Promise((resolve, reject) => {
//         axios
//             .post('v1/roster/sessions', sessionData)
//             .then((response) => resolve(response))
//             .catch((error) => reject(error));
//     });
// },
const exportSessionToMonday = (ctx, { sessionId, integrity }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/integration/monday/export', { sessionId, integrity })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
// updateSession(ctx, { sessionId, sessionData }) {
//     // Sanitize the request body before sending it the server to prevent strict validation from killing the request
//     delete sessionData.id;
//     delete sessionData.createdAt;
//     delete sessionData.updatedAt;
//
//     return new Promise((resolve, reject) => {
//         axios
//             .patch(`/roster/sessions/${sessionId}`, sessionData)
//             .then((response) => resolve(response))
//             .catch((error) => reject(error));
//     });
// },
// addSessionToPathway(ctx, { pathwayId, sessionId }) {
//     return new Promise((resolve, reject) => {
//         axios
//             .post(`v1/roster/pathways/${pathwayId}/sessions`, { sessionId })
//             .then((response) => resolve(response))
//             .catch((error) => reject(error));
//     });
// },
const fetchSessionAttendance = (ctx, { sessionId, sectionId, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v1/roster/attendances', { params: { ...queryParams, section: sectionId, session: sessionId } })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchSessionAttendanceTable = (ctx, { id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/attendances/table/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createSessionClassCheckin = (ctx, { sessionId, classId, dateTime }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/roster/attendances/${sessionId}/checkin/${classId}`, { dateTime })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createSectionClassCheckin = (ctx, { sectionId, classId, dateTime }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/roster/attendances/section/${sectionId}/checkin/${classId}`, { dateTime })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const updateSessionAttendance = (ctx, { id, data }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  delete data.id;
  delete data.createdAt;
  delete data.updatedAt;

  return new Promise((resolve, reject) => {
    axios
      .patch(`v1/roster/attendances/${id}`, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const exportSessionAttendance = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/attendances/export/${id}`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const exportSectionAttendance = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/attendances/export/section/${id}`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const exportProfileAttendance = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/attendances/export/profile/${id}`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const transferStudentToAnotherCohort = (ctx, { cohortId, profileId, reassignReason, loa }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/sessions/${cohortId}/students`, { profileId, reason: reassignReason, loa })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  createSessionClassCheckin,
  createSectionClassCheckin,
  exportSessionAttendance,
  exportSectionAttendance,
  exportProfileAttendance,
  exportSessionToMonday,
  fetchSessionAttendanceTable,
  fetchSessionAttendance,
  updateSessionAttendance,
  transferStudentToAnotherCohort,
};
